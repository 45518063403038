import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import SiteMetadata from "../componets/SiteMetadata/SiteMetadata"
import Header from "../componets/Header/Header"
import Slideshows from "../componets/Slideshows/Slideshows"
import Alaune from "../componets/Alaune/Alaune"
import Textemploy from "../componets/Textemploy/Textemploy"
import CentresdeFormationsMobile from "../componets/CentresdeFormations/CentresdeFormationsMobile"
import Niveauformation from "../componets/Niveauformation/Niveauformation"
import Domainedeformations from "../componets/Domainedeformations/Domainedeformations"
import DomainedeformationsMobile from "../componets/Domainedeformations/DomainedeformationsMobile"
import NiveauformationMobile from "../componets/Niveauformation/NiveauformationMobile"
import Topbottom from "../componets/Topbottom/Topbottom"
import Footer from "../componets/Footer/Footer"
import Wrapper from "../componets/Wrapper/Wrapper"

import "./globals.css"
import {
  mediaUrl,
  translateRegion,
  translateCity,
  translateSector,
  translateType,
  translateLevel,
  translateTraining,
  translateFeaturedTraining,
  translateAgency,
} from "../services/helpers"

export default function Home(props) {
  const data = useStaticQuery(graphql`
    {
      hasura {
        seformer_db_region(order_by: { name: asc }) {
          region_id
          name
          db_country {
            country_id
            name
          }
        }
        seformer_db_city(order_by: { name: asc }) {
          city_id
          name
          db_region {
            region_id
            name
          }
        }
        seformer_db_sector(order_by: { name: asc }) {
          sector_id
          name
          db_media {
            link_full
          }
        }
        seformer_db_training_type(order_by: { name: asc }) {
          type_id
          name
        }
        seformer_db_level(order_by: { name: asc }) {
          level_id
          name
          image {
            link_full
          }
        }
        seformer_db_article(
          where: { status: { _eq: "active" } }
          order_by: { date_create: desc, date_update: desc }
        ) {
          article_id
          reference
          title
          contact_recrutment_email
          db_training_article {
            goals
            programm
            job_targeted
            training_hours
            training_days
            training_months
            success_rate
            db_city {
              city_id
              name
              postal_code
              db_region {
                region_id
                name
              }
            }
            db_training_article_public_admis {
              db_public_admi {
                public_admis_id
                name
              }
            }
            db_training_article_mobilization_devices {
              db_mobilization_device {
                mobilization_device_id
                name
              }
            }
            db_degree {
              degree_id
              name
            }
          }
          db_training_article {
            db_city {
              city_id
              name
              db_region {
                region_id
                name
              }
            }
          }
          db_article_option_paids(where: { is_active: { _eq: true } }) {
            db_option {
              name
              option_id
              duration
              db_option_type {
                value
              }
            }
            db_payement {
              date_payement
            }
          }
          db_sector {
            sector_id
            name
          }
          db_level {
            level_id
            name
          }
          db_training_article_types {
            db_training_type {
              type_id
              name
            }
          }
          info_agency
          db_agency {
            agency_id
            name
            db_public_info_agency {
              agency_description
              website
              dbMediaByMainLogoId {
                link_full
              }
              db_address {
                address
                additional_address
                db_city {
                  name
                  db_region {
                    name
                  }
                }
              }
            }
          }
        }
        seformer_db_agency(order_by: { name: asc }) {
          agency_id
          name
          db_public_info_agency {
            dbMediaByMainLogoId {
              link_full
            }
          }
          db_articles(
            where: { status: { _eq: "active" } }
            order_by: { date_create: desc, date_update: desc }
          ) {
            article_id
            title
            status
          }
          articles: db_articles_aggregate(
            where: { status: { _eq: "active" } }
          ) {
            aggregate {
              count
            }
          }
        }
      }

      strapiHomepage {
        short_description {
          title
          description
          button_text
          image {
            name
          }
        }
        about_us {
          title
          description
        }
        banner_top {
          childImageSharp {
            fluid(maxWidth: 1700, webpQuality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        banner_middle {
          childImageSharp {
            fluid(maxWidth: 1700, webpQuality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        banner_top_link
        banner_middle_link
      }
      logo: file(
        absolutePath: {
          regex: "/images/logo_seformer-f59ecb37be2dc067b743d7ee0a113952.webp/"
        }
      ) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slideBgImage: file(
        absolutePath: {
          regex: "/images/img-slide-hp-6ca02b9b8adb986a503d9ad6e2992964.webp/"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      shortDescriptionImg: file(
        absolutePath: {
          regex: "/images/7e2481db59ce03de9395ba70daa1bd9d-8c2b9ef0069ed6d0f10f6ea262dbac03.webp/"
        }
      ) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      levelImages: allFile(filter: {name: {regex: "/--LEVEL/"}}) {
        nodes {
          name
          childImageSharp {
            fluid(quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const metas = {
    title:
      "Annonce de formation et d'offre de stage à La Réunion, Dom Crom, Océan Indien",
    metatitle: "Formation et stage  La Réunion - Se Former.re",
    description:
      "Annonce d'offres de formation et de stage à La Réunion. Tous secteurs d'activité, pour tous les niveaux, à distance, en alternance, formation en continu ou formation professionnelle",
    keywords:
      "formation, stage, La Réunion, drom, com, annonce, formation à distance, formation en continu, professionnelle, en alternance, Mayotte, Maurice, Océan Indien, Antilles, Madagascar",
  }

  const regions = data.hasura.seformer_db_region.map(item =>
    translateRegion(item)
  )
  const cities = data.hasura.seformer_db_city.map(item => translateCity(item))
  const sectors = data.hasura.seformer_db_sector.map(item =>
    translateSector(item)
  )
  const types = data.hasura.seformer_db_training_type.map(item =>
    translateType(item)
  )
  const levels = data.hasura.seformer_db_level.map(item => translateLevel(item))
  const levelImages = data.levelImages.nodes;
  const trainings = data.hasura.seformer_db_article.map(item =>
    translateTraining(item)
  )
  const featuredTrainings = data.hasura.seformer_db_article
    .filter(item =>
      item.db_article_option_paids.filter(
        option_paids =>
          option_paids.option &&
          option_paids.option.active &&
          option_paids.option.db_option_type.value === "news"
      )
    )
    .map(item => translateFeaturedTraining(item))
  const agencies = data.hasura.seformer_db_agency.map(item =>
    translateAgency(item)
  )
  
  return (
    <Wrapper>
      <SiteMetadata metas={metas} />
      <Header {...props} logo={data.logo} />
      <Slideshows
        types={types}
        cities={cities}
        regions={regions}
        sectors={sectors}
        trainings={trainings}
        bgImage={data.slideBgImage}
      />
      <Alaune
        trainings={featuredTrainings}
        bannerTop={data.strapiHomepage.banner_top}
        bannerTopLink={data.strapiHomepage.banner_top_link}
      />
      <Textemploy
        shortDescription={data.strapiHomepage.short_description}
        shortDescriptionImage={data.shortDescriptionImg}
      />
      <CentresdeFormationsMobile agencies={agencies} />
      <Domainedeformations sectors={sectors} />
      <Niveauformation
        levels={levels}
        levelImages={levelImages}
        bannerMiddle={data.strapiHomepage.banner_middle}
        bannerMiddleLink={data.strapiHomepage.banner_middle_link}
      />
      <div className="section-gris-mobile">
        <DomainedeformationsMobile
          sectors={sectors}
          bannerMiddle={data.strapiHomepage.banner_middle}
          bannerMiddleLink={data.strapiHomepage.banner_middle_link}
        />
        <NiveauformationMobile levels={levels} />
      </div>
      <Topbottom aboutUs={data.strapiHomepage.about_us} />
      <Footer />
    </Wrapper>
  )
}
